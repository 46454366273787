import React from "react"
import { graphql } from "gatsby"
import MarkdownPage from "./MarkdownTemplate/MarkdownPage";

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <div className="markdown-page-container">
      <MarkdownPage title={frontmatter.title} date={frontmatter.date} featuredImage={frontmatter.featuredImage} html={html}/>
    </div>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        featuredImage
      }
    }
  }
`
