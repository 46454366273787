import React from "react";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Footer from "components/Footer/Footer.jsx";
import markdownPageStyle from "assets/jss/material-kit-react/views/markdownPage.jsx";
import MarkdownSection from "./Sections/MarkdownSection.jsx";
import { withPrefix } from "gatsby"

const dashboardRoutes = [];

class MarkdownPage extends React.Component {
  render() {
    const { classes, title, date, featuredImage, html, ...rest } = this.props;
    return (
      <div>
        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand="Finwe Ltd."
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 150,
            color: "white"
          }}
          {...rest}
        />
        {/*<Parallax filter image={require("assets/img/default_background.jpg")} small>*/}
        {/*<Parallax filter image={require({featuredImage})} small>*/}
        <Parallax filter image={withPrefix(featuredImage)} small>
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <h1 className={classes.title}>{title}</h1>
                <h4>{date}</h4>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <MarkdownSection html={html}/>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withStyles(markdownPageStyle)(MarkdownPage);
