import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import markdownStyle from "assets/jss/material-kit-react/views/markdownSections/markdownStyle.jsx";

class MarkdownSection extends React.Component {
  render() {
    const { classes, html } = this.props;
    return (
      <div className={classes.section}>
        <div className={classes.container}>
          <div className={classes.typo} dangerouslySetInnerHTML={{ __html: html }} style={markdownStyle}/>
        </div>
      </div>
    );
  }
}

export default withStyles(markdownStyle)(MarkdownSection);
